<template>
<div class="language">
  <div class="top-back" >
    <img class="top-image" src="@/assets/images/back.png" alt="" @click="jumpUrl('/home')">
    <div>{{$t('login.yyqh')}}</div>
    <span @click="submit">{{$t('login.bc')}}</span>
  </div>

  <ul>
    <li v-for="(item,i) in langeList" :key="i" @click="getLange(item)">
      {{item.label}}
      <van-icon name="success" color="#6FFEE8" size="19" v-if="item.value===lange"/>
    </li>
  </ul>
</div>
</template>
<script setup>
import { ref,onBeforeMount,getCurrentInstance} from 'vue'
import { useRouter} from "vue-router";
import {Toast} from 'vant'
const router = useRouter()
const jumpUrl=(url)=>{
  router.push(url)
}
// eslint-disable-next-line no-unused-vars
// 多语言
import { useI18n } from 'vue-i18n'
const { $t } = getCurrentInstance().proxy;
const {locale } = useI18n()
const lange=ref(null)
const langeList=ref([
  { label: '中文繁體', value: 'zh-HK' },
  { label: 'English', value: 'en-US' },
  // { label: '韩文', value: 'ko-KR' },
  // { label: '日文', value: 'ja-JP' },
  // { label: '中文简体', value: 'zh-CN' },

])
const getLange=(item)=>{
  lange.value=item.value
}
const submit=()=>{
  sessionStorage.setItem('lange',lange.value)
  locale.value=lange.value
  Toast($t('home.success'))
  jumpUrl('/home')
}
onBeforeMount(()=>{
  lange.value=locale.value
})
</script>
<style scoped lang="less">
.language{
  padding: 0px 18px 0 18px;
  background-image: url("~@/assets/images/bg_pic.png");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: bottom;
  min-height: 100vh;
  .top-back{
    width: 100%;
    height: 52px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: bold;
    font-size: 17px;
    color: #FFFFFF;
    position: relative;
    .top-image{
      position: absolute;
      left: 0px;
      top: 18px;
      width: 16px;
      height: 15px;
    }
    span{
      font-weight: 500;
      font-size: 15px;
      color: #6FFEE8;
      position: absolute;
      right: 0px;
      top: 18px;
    }
  }

  ul{
    li{
      display: flex;
      align-items: center;
      justify-content: space-between;
      font-weight: 500;
      font-size: 17px;
      color: #FFFFFF;
      padding: 30px 20px 30px 0;
      border-bottom: 1px solid #7E8D95;
    }
  }
}
</style>
